.carouselwrapper {
  background-color: #1C1C1C; /* Set your desired background color */
  padding: 20px;
  overflow: hidden; 
}

.carousel {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0 3% 3% 3%; /* Center the carousel horizontally */
  overflow-x: hidden; 
  scroll-snap-type: none; 
  -webkit-overflow-scrolling: touch; 
  scroll-behavior: smooth; 
}

.card {
  flex: 0 0 auto;
  margin: 1%; /* Set margin for each card */
  scroll-snap-align: start; 
  width: 250px; /* Fixed width */
  height: 250px; /* Fixed height */
  display: flex;
  align-items: center; /* Center content vertically */
  justify-content: center; /* Center content horizontally */
  overflow: hidden; /* Ensure images don't overflow */
}

.material-icons {
  max-width: 100%; 
  max-height: 100%; 
  object-fit: contain; /* Maintain aspect ratio */
  display: block;
  margin: 0 auto; /* Center image */
}

.ui {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px; 
}

.prev,
.next {
  background-color: #C9130A;
  color: #fff; 
  border: none;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.clients-title {
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: #C9130A; 
  margin-bottom: 20px;
}

.clients-content {
  padding: 5%;
}

#clients hr {
  width: 5%; 
  height: 5px;
  background-color: #FFF; 
  border: none; 
  margin: 20px auto; 
}

@media screen and (max-width: 1366px) {
  .carousel {
    margin: 0 7% 3% 7%;
  }

  .card {
    flex: 0 0 calc(100% / 4 - 4%); /* Adjust flex basis to fit three cards in a row */
  }
}

@media screen and (max-width: 1024px) {
  .card {
    flex: 0 0 calc(100% / 3 - 4%); /* Adjust flex basis to fit two cards in a row */
  }
}

@media screen and (max-width: 600px) {
  .card {
    flex: 0 0 calc(100% - 2%); /* Make each card occupy full width on smaller screens */
  }
}
