/* General Gallery Styles */
.gallery {
    padding: 20px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Grid Container */
.gallery-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3-column grid */
    gap: 10px; /* Small gap between grid items */
    max-width: 1200px; /* Set a max-width for the grid container */
    width: 90%; /* Ensure the grid container takes full width */
}

/* Card Styling */
.gallery-item {
    position: relative;
    overflow: hidden;
    background-color: #fff;
}

/* Different Image Sizes */
.gallery-item:nth-child(3n) {
    grid-row: span 2; /* Span two rows for every third image */
    height: auto;
}

.gallery-item:nth-child(4n) {
    grid-row: span 1.5; /* Span one and a half rows for every fourth image */
    height: auto;
}

.gallery-item:nth-child(5n) {
    grid-row: span 2.5; /* Span two and a half rows for every fifth image */
    height: auto;
}

.gallery-item:nth-child(7n) {
    grid-row: span 1; /* Span one row for every seventh image */
    height: auto;
}

/* Image Styling */
.gallery-image {
    width: 100%;
    height: 100%; /* Ensure the image takes up the full height of the container */
    object-fit: cover; /* Cover the container while maintaining the aspect ratio */
    display: block;
    transition: transform 0.3s ease; /* Smooth scaling transition */
}

/* Image Hover Effect */
.gallery-item:hover .gallery-image {
    transform: scale(1.05); /* Slightly scale image on hover */
}

/* Load More Button Styling */
.load-more {
    display: inline-block;
    align-items: center;
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #C9130A;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    border-radius: 50px;
    border: none;
}

.load-more:hover {
    background-color: #A31008;
  color: #fff;
}

/* Responsive Breakpoints */
@media (max-width: 1200px) {
    .gallery-grid {
        grid-template-columns: repeat(2, 1fr); /* Adjust for medium screens */
    }
}

@media (max-width: 768px) {
    .gallery-grid {
        grid-template-columns: repeat(2, 1fr); /* One column on smaller screens */
    }

    .gallery-item {
        grid-row: span 2; /* Span two rows on smaller screens */
    }
}
