.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #1C1C1C;
}

.services-content {
  width: 98%;
  margin: 0 auto;
  text-align: center;
  color: #C9130A;
  padding: 5% 5% 0 5%;
}

.services-content hr {
  width: 5%; 
  height: 5px;
  background-color: #FFF; 
  border: none; 
  margin: 20px auto; 
}

.service {
  flex: 1 0 calc(25% - 20px);
  padding: 20px 40px;
  color: #f9f9f9;
  margin: 10px; 
  text-align: center;
}

.service h3 {
  margin-top: 0;
  color: #fff;
}

.service p {
  margin-bottom: 15px;
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .service {
    flex-basis: calc(50% - 20px);
  }
}

@media screen and (max-width: 576px) {
  .service {
    flex-basis: calc(100% - 20px);
    padding: 0 10%; 
  }
}
