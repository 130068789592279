.about-container {
  display: flex;
  flex-direction: column;
  background-color: black;
}

.about-content {
  background-color: #1C1C1C;
  padding: 40px;
  color: #ffffff;
  text-align: center;
}

.about-content h1 {
  font-size: 2rem;
  color: #C9130A;
  text-align: center;
}

.about-content .about-text {
  font-size: 1rem;
  line-height: 1.5;
  text-align: center;
}

.vid {
  height: 315px;
}

.video {
  width: 100%;
  height: 100%; /* Set height to 100% */
}

.contact-button {
  display: inline-block;
  align-items: center;
  padding: 10px 20px;
  background-color: #C9130A;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
  border-radius: 50px;
}

.contact-button:hover {
  background-color: #A31008;
  color: #fff;
}

.about-social-links {
  position: fixed;
  bottom: 50px;
  top: 10%;
  z-index: 100; /* Ensure the icons stay on top of other content */
}

.about-social-links ul {
  list-style: none;
  padding: -10%;
  margin-top: 0;
}

.about-social-links li {
  margin-bottom: 10px;
}

.about-social-links a {
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center;     /* Vertically center */
  width: 40px;
  height: 40px;
  color: #C9130A;
  font-size: 24px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.05); /* Light background for better contrast */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.about-social-links a:hover {
  background-color: #555;
  color: #fff; /* Hover effect for better visibility */
}

@media screen and (min-width: 768px) {
  .about-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  .about-content,
  .vid {
    flex: 1;
    height: 580px;
  }


  .about-content h1 {
    font-size: 62px;
    padding-bottom: 2%;
    font-weight: 600;
  }

  .about-content .about-text {
    line-height: 2rem;
    padding-bottom: 3%;
  }

  .about-content h1,
  .about-content .about-text {
    width: 70%;
    margin: auto;
  }

  .about-content {
    padding: 5% 0;
  }
}