/* Updated goals.css */

.goal-section {
  width: 90%;
  margin: auto;
  padding: 5% 0;
  display: flex;
  justify-content: space-between;
}

.goals,
.core-values,
.mission,
.vision {
  width: calc(25% - 2%);
  padding: 20px;
  margin: 1%;
  position: relative;
  display: flex; /* Added */
  flex-direction: column; /* Added */
  align-items: center; /* Added */
}

.icon {
  font-size: 40px;
  color: white;
  background-color: #C9130A;
  padding: 10%;
  border-radius: 50%;
  display: flex;
  text-align: center; 
  justify-content: flex-start;
}

.mission h2,
.vision h2,
.core-values h2,
.goals h2 {
  margin-top: 0;
}

.goal-section hr {
  width: 5px;
  margin: 0;
  border: none;
  background-color: #C9130A;
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
  .goal-section {
    flex-wrap: wrap;
    width: 100%;
  }

  .goals,
  .core-values,
  .mission,
  .vision {
    width: 100%;
    border-bottom: #C9130A solid 1px;
  }

  .goal-section hr {
    display: none;
  }

  .goal-section {
    padding: 0 7%;
  }
}
