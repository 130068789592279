section {
  position: relative;
  z-index: 3;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #fff;
}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #c9130a;
  font-weight: bold;
  font-size: 2em;
}

.section-header p {
  color: #1C1C1C;
}

.contacts-content {
  padding: 5%;
}

#contacts hr {
    width: 5%; 
    height: 5px;
    background-color: #c9130a; 
    border: none; 
    margin: 20px auto; 
}

.row  {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.contact-info {
  width: 50%;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
}

.contact-info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: #c9130a; 
  border-radius: 50%; 
  margin-right: 10px; 
}

.contact-info-icon svg {
  font-size: 20px; 
  color: #fff; 
}


.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #c9130a;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #1C1C1C;
  font-size: 1em;
}

.contact-form {
  background-color: #fff;
  padding: 40px;
  width: 45%;
  padding-bottom: 20px;
  padding-top: 20px;
}

.contact-form h2 {
  font-weight: bold;
  font-size: 2em;
  margin-bottom: 10px;
  color: #333;
}

.contact-form .input-box {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.contact-form .input-box input,
.contact-form .input-box textarea{
  width: 100%;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  border: none;
  border-bottom: 2px solid #333;
  outline: none;
  resize: none;
}

.contact-form .input-box span {
  position: absolute;
  left: 0;
  padding: 5px 0;
  font-size: 16px;
  margin: 10px 0;
  pointer-events: none;
  transition: 0.5s;
  color: #666;
}

.contact-form .input-box input:focus ~ span,
.contact-form .input-box textarea:focus ~ span{
  color: #e91e63;
  font-size: 12px;
  transform: translateY(-20px);
}

.contact-form .input-box input[type="submit"]
{
  width: 30%;
  margin: auto;
  background: #c9130a;
  color: #FFF;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 18px;
  transition: 0.5s;
  border-radius: 50px;
}

.contact-form .input-box input[type="submit"]:hover
{
  background: #A31008;
  color: #fff;
}

@media (max-width: 991px) {
  section {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  
  .row {
    flex-direction: column;
  }
  
  .contact-info {
    margin-bottom: 40px;
    width: 100%;
  }
  
  .contact-form {
    width: 100%;
  }
}

@media (max-width: 767px) {
section {
  width: 80%;
  margin: auto;
}
}