* {
  margin: 0;
  padding: 0;
}

.pg-footer {
  font-family: "Roboto", sans-serif;
}

.footer {
  background-color: #1C1C1C;
  color: #fff;
}
.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}
.footer-wave-path {
  fill: #fff;
}

.footer-content {
  margin-left: auto;
  margin-right: auto;
  max-width: 1230px;
  width: 90%;
  padding: 40px 15px 450px;
  position: relative;
}

.footer-content-column {
  box-sizing: border-box;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  color: #fff;
}

.footer-content-column ul li a {
  color: #fff;
  text-decoration: none;
}

.footer-logo-link {
  display: inline-block;
}

.footer-logo-link {
  display: inline-block;
  color: #fff;
  text-decoration: none;
}

.footer-logo-link:hover {
  color: #c9130a;
}

.footer-menu {
  margin-top: 30px;
}

.footer-menu-name {
  color: #fffff2;
  font-size: 15px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-menu-list {
  list-style: none;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}
.footer-menu-list li {
  margin-top: 5px;
}

.footer-call-to-action-description {
  color: #fffff2;
  margin-top: 10px;
  margin-bottom: 20px;
}
.footer-call-to-action-button:hover {
  background-color: #A31008;
  color: #fff;
}
.button:last-of-type {
  margin-right: 0;
}
.footer-call-to-action-button {
  background-color: #c9130a;
  border-radius: 21px;
  color: #fffff2;
  display: inline-block;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  padding: 12px 30px;
  margin: 0 10px 10px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s;
  cursor: pointer;
  position: relative;
}
.footer-call-to-action {
  margin-top: 30px;
}
.footer-call-to-action-title {
  color: #fffff2;
  font-size: 14px;
  font-weight: 900;
  letter-spacing: 0.1em;
  line-height: 18px;
  margin-bottom: 0;
  margin-top: 0;
  text-transform: uppercase;
}
.footer-call-to-action-link-wrapper {
  margin-bottom: 0;
  margin-top: 10px;
  color: #fff;
  text-decoration: none;
}
.footer-call-to-action-link-wrapper a {
  color: #FFF;
  text-decoration: none;
}

.footer-social-links {
  bottom: 0;
  height: 54px;
  position: absolute;
  right: 0;
  width: 350px;
}

.footer-social-amoeba-svg {
  height: 54px;
  left: 0;
  display: block;
  position: absolute;
  top: 0;
  width: 350px;
}

.footer-social-amoeba-path {
  fill: #FFF;
}

.footer-social-link {
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  color: #c9130a;
}

.footer-social-link a {
  color: #c9130a;
  text-decoration: none;
}

.footer-social-link:hover {
  color: #A31008;
}

.footer-social-link.youtube {
  left: 10%;
  top: 15px;
}

.footer-social-link.twitter {
  left: 21%;
  top: 15px;
}

.footer-social-link.facebook {
  left: 38%;
  top: 15px;
}

.footer-social-link.instagram {
  left: 55%;
  top: 15px;
}

.footer-social-link.linkedin {
  left: 70%;
  top: 15px;
}

.footer-social-link.tiktok {
  left: 82%;
  top: 15px;
}

.footer-copyright {
  background-color: #FFF;
  color: #c9130a;
  padding: 15px 30px;
  text-align: center;
}

.footer-copyright-wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

.footer-copyright-link {
  color: #c9130a;
  text-decoration: none;
  font-size: 12px;
}

.footer-copyright-link:hover {
  color: #A31008;
}

@media (min-width: 320px) and (max-width: 479px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width: 480px) and (max-width: 599px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}
@media (min-width: 600px) and (max-width: 800px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 1050px;
    position: relative;
  }
}

@media (min-width: 768px) {
  .footer-content {
    margin-left: auto;
    margin-right: auto;
    max-width: 1230px;
    padding: 40px 15px 450px;
    position: relative;
  }

  .footer-wave-svg {
    height: 50px;
  }

  .footer-content-column {
    width: 24.99%;
  }
}
