.text {
  color: #fff;
  font-weight: bold;
}

.text:hover,
.text:active {
  text-decoration: none;
  color: #C9130A;
  cursor: pointer;
}

a,
a[aria-current="page"] {
  text-decoration: none;
}

.h5 {
  padding: 0 4%;
}

.projects-container {
  background-color: #fff;
  width: 94%;
  margin: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-content {
  padding: 5%;
}

.projects-title {
  font-size: 2rem;
  font-weight: bold;
  text-align: center;
  color: #C9130A;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  margin: 20px auto;
  width: 100%;
}

.project {
  position: relative;
  overflow: hidden;
}

.back {
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end; /* Ensure alignment */
}

.project-details {
  width: 100%;
  padding: 5px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  text-align: center;
  bottom: 0; /* Align towards the bottom */
}

.project-details h2,
.project-details p {
  margin: 0;
}

.project-details h2 {
  font-size: 1.5rem;
}

.project-details p {
  font-size: 0.75rem;
}

.project-details .details-row {
  display: flex;
  justify-content: space-around
}

.project-details .details-column, 
.project-details .details-column1 {
  padding: 10px;
}

.project-details .details-column1 {
  display: flex;
  justify-content: space-between;
}

.project-details .countdown-item {
  border-radius: 5px;
  padding: 5px;
  margin-right: 5px; 
  transform: scale(0); 
  animation: scaleIn 0.5s ease forwards; 
}


@keyframes scaleIn {
  from {
    transform: scale(0); 
  }
  to {
    transform: scale(1);
  }
}

.project-details .countdown-item p {
  font-size: 24px;
  font-weight: bolder;
  color: #C9130A;
}

.project-details .countdown-item span {
  display: block;
  font-size: 12px;
  color: #666;
}

.projects-content hr {
  width: 30%;
  height: 5px;
  background-color: black;
  border: none;
  margin: 20px auto;
}

.projects-call-to-action {
  margin: 2% 0;
  text-decoration: none;
  color: #FFF;
  background-color: #C9130A;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: bold;
  font-size: 1.2rem;
}

.projects-call-to-action:hover {
  text-decoration: none;
  background-color: #A31008;
  color: #fff !important;
}

.offcanvas-custom .offcanvas-body {
  max-height: 50vh; /* Adjust the value as needed */
  overflow-y: auto; /* Add vertical scroll if content exceeds maxHeight */
}

@media screen and (max-width: 768px) {
  .projects-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }

  .project-details .details-row {
    display: flex;
    flex-direction: column;
  }

  .h5 {
    display: none;
  }

  .text {
    color: #C9130A;
  }
  
  .text:hover,
  .text:active {
    color: #1C1C1C;
  }

  .projects-container {
    width: 86%;
    margin: auto;
  }
  
}
