
  .projects-container1 {
    background-color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin: auto;
  }
  
  .projects-title1 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    color: #C9130A;
  }
  
  .project1 {
    position: relative;
    overflow: hidden;
    padding: 3%;
  }

  .project1:nth-child(even) {
    flex-direction: row-reverse;
  }
  
  .back1 {
    width: 100%;
    height: 400px;
    background-size: cover;
    background-position: center;
    position: relative;
    display: flex;
    align-items: flex-end;
  }
  
  .project-details1 {
    width: 100%;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: start;
    padding: 0 3%;
  }
  
  .project-details1 h2,
  .project-details1 p {
    margin: 0;
  }
  
  .project-details1 .details-row {
    display: flex;
    justify-content: space-around
  }
  
  .project-details1 .details-column, 
  .project-details1 .details-column1 {
    padding: 10px;
  }
  
  .project-details1 .details-column1 {
    display: flex;
    justify-content: space-between;
  }
  

  .projects-container1 hr {
    width: 100%;
    height: 1px;
    background-color: #C9130A;
    border: none;
    margin: 20px auto;
  }
  
  .project-details1 button {
    display: inline-block; /* Ensures the button is only as wide as its content */
    align-items: center;
    padding: 10px 20px;
    margin: 20px auto; /* Centers the button and adds space above and below */
    background-color: #C9130A;
    color: #fff;
    font-size: 16px;
    text-decoration: none;
    border-radius: 50px;
    border: none;
    text-align: center; /* Centers the text inside the button */
    width: auto; /* Adjust the width if needed */
    max-width: 200px; /* Optional: Set a maximum width for the button */
}

  

.project-details1 button:hover {
  background-color: #A31008;
  color: #fff;
}

.project-details1 button a {
    text-decoration: none;
    color: #fff;
}

@media screen and (max-width: 768px) {
  .projects-container1 {
    width: 100%;
  }
  
  .project1 {
    flex-direction: column;
  }
  
  .back1 {
    height: 200px;
  }
  
  .project-details1 {
    padding: 0;
  }
  
  .project-details1 .details-row {
    flex-direction: column;
  }
  
  .project-details1 .details-column, 
  .project-details1 .details-column1 {
    padding: 0;
  }
  
  .project-details1 button {
    width: 50%;
  }
}